import './App.css';
import {useState} from "react";

function App() {

    const [noCount, setNoCount] = useState(0);
    const [yesPressed, setYesPressed] = useState(false);
    const yesButtonSize = noCount * 20 + 16;
    let audio = new Audio("./happy.mp3")

    const start = () => {
        audio.play();
    }

    const handleNoClick = () => {
        setNoCount(noCount + 1);
    };

    const getNoButtonText = () => {
        const phrases = [
            "Нет",
            "Уверена?",
            "А что, если я вежливо попрошу?",
            "Пожалуйста, пожалуйста",
            "Я буду с шоколадкой...",
            "А если с мороженным?",
            "Все равно нет?👀",
            "Но :*(",
            "Я умру",
            "Я мертв",
            "Ты разговариваешь с призраком...",
            "Не передумала?",
            "ОЧЕНЬ ПОЖАЛУЙСТА",
            ":((((",
            "Нет :(",
        ];

        return phrases[Math.min(noCount, phrases.length - 1)];
    };
    return (
        <div className="-mt-16 flex h-screen flex-col items-center justify-center">
            {yesPressed ? (
                <>
                    <img src="https://media1.tenor.com/m/o656qFKDzeUAAAAC/rick-astley-never-gonna-give-you-up.gif"/>
                    <div className="my-4 text-4xl font-bold">Увидимся❤️🖤</div>
                </>
            ) : (
                <>
                    <img
                        className="h-[200px]"
                        src="https://gifdb.com/images/high/cute-love-bear-roses-ou7zho5oosxnpo6k.gif"
                    />
                    <h1 className="my-4 text-4xl">Будешь моей Валентиной?</h1>
                    <div className="flex items-center">
                        <button
                            className={`mr-4 rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-green-700`}
                            style={{fontSize: yesButtonSize}}
                            onClick={() => {
                                setYesPressed(true);
                                start();
                            }}
                        >
                            Да
                        </button>
                        <button
                            onClick={handleNoClick}
                            className=" rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
                        >
                            {noCount === 0 ? "Нет" : getNoButtonText()}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default App;
